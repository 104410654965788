import React from 'react';
import { Container, MinPageHeight } from '../styles/globalStyles';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Sidenote from '../components/Sidenote';

const Changelog = ({ changelogBody }) => {
  return (
    <Layout>
      <Seo title="Vinay Raghu - Changelog"></Seo>
      <MinPageHeight>
        <Container>
          <h1>Changelog</h1>
          <Sidenote title="">
            I started maintaining a changelog to keep track of all the changes
            this site has gone through. There's been a ton of work done before I
            even started tracking and the format keeps evolving till I am happy
            with it. But this is a good way to keep track of all notable changes
            to my website. Read
            <a
              href="https://keepachangelog.com/en/1.0.0/"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              keep a changelog{' '}
            </a>
            for more information. As much as reasonably possible, I try to stick
            with
            <a
              href="https://semver.org/spec/v2.0.0.html"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              semantic versioning
            </a>
            . Generated by{' '}
            <a
              href="https://github.com/CookPete/auto-changelog"
              target="_blank"
              rel="noreferrer"
            >
              auto-changelog
            </a>
            .
          </Sidenote>
          <div dangerouslySetInnerHTML={{ __html: changelogBody }}></div>
        </Container>
      </MinPageHeight>
    </Layout>
  );
};

const ChangelogDataQuery = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/changelog/" } }
        ) {
          edges {
            node {
              changelogBody: html
            }
          }
        }
      }
    `}
    render={(data) => (
      <Changelog
        changelogBody={data.allMarkdownRemark.edges[0].node.changelogBody}
      />
    )}
  />
);

export default ChangelogDataQuery;
